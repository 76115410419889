import React, { FC } from 'react'
import { getSize } from 'utils/helpers'
import { IChildrenOpt } from 'utils/interface'
import { NameStyled } from './common.styled'

interface IName  extends IChildrenOpt {
  name: string;
  big?: boolean;
}

export const NameWrapper: FC<IName> = ({
  name,
  big = false,
  children = ''
}) => (
  <NameStyled big={big} size={getSize(name, 10, 20)}>{ children }</NameStyled>
)