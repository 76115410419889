import React, { FC } from 'react'

import useAuth from 'use/auth/useAuth'
import { HeaderAvatar } from 'components/common/common.styled'
import { routes } from 'setup/constants'

export const HeaderUser: FC = ({
}) => {
  const user  = useAuth().user
  return (
    <>{ user && <HeaderAvatar linkTo={routes.userProfile} />}</>
  )
}