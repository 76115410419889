import React, { FC, memo } from 'react';
import { randomColor } from 'utils/helpers';

import { AvatarStyled, ImageStyled } from './styled';

interface IProps {
  src: string;
  alt: string;
  className?: string;
}



const AvatarImageMemo: FC<IProps> = ({ src, alt, className }) => { 
  const emptySrc = !src && alt.split(' ').map(item => item.charAt(0))

  return (
    <AvatarStyled className={className} style={{
      backgroundColor: randomColor(alt)
    }}>
      { src ? <ImageStyled src={src} alt={alt} /> : emptySrc }
    </AvatarStyled>
  );
};

export const AvatarImage = memo(AvatarImageMemo);
