import React, { FC } from 'react';

import { IChildrenOpt } from 'utils/interface';

import {
  AvatarColor,
  AvatarWhite,
  CustomCardBody,
  CustomRaisedCardColor,
  CustomRaisedCardWhite,
  CustomCardColorHeaderRaised,
  CustomCardWhiteHeaderRaised
} from './styled';
import { Icon } from 'components/styled';
import { IconDefinition } from 'components/theme/icons';


interface IProps extends IChildrenOpt {
  icon?: IconDefinition;
  background?: string;
  variant?: 'invert';
  color?: string;
  overflow?: boolean;
  content?: React.ReactNode;
  className?: string;
}

export const CardRaised: FC<IProps> = ({
  icon = '',
  background = '',
  variant,
  color = '',
  content = '',
  overflow = false,
  children = '',
  className
}) => {
  const inverted = variant === 'invert'
  const Wrapper = inverted ? CustomRaisedCardWhite : CustomRaisedCardColor
  const Header = inverted ? CustomCardColorHeaderRaised : CustomCardWhiteHeaderRaised
  const Avatar = inverted ? AvatarWhite : AvatarColor
  const Body = CustomCardBody
  return (
    <Wrapper color={color} className={className}
      background={background}>
      <Header color={color}>
        {icon &&
          <Avatar color={color}><Icon icon={icon} /></Avatar>}
        {content}
      </Header>
      { children && <Body overflow={overflow}>{children}</Body>}
    </Wrapper>
  );
};
