import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Avatar, Badge } from 'components/core';
import { dangerColor, infoColor, warningColor, whiteColor } from 'components/theme/constants';

export const AvatarStyled = styled(Avatar)`
  & img {
    width: 100%;
    height: auto;
  }
`
export const AvatarBadgeStyled = styled(Badge).attrs(({
  overlap: 'circular'
}))`
  display: flex;
  .MuiBadge-badge {
    padding: 0;
    right: 0;
    
    & svg {
      color: ${infoColor[0]};
      background: ${whiteColor};
    }
    & span {
      background: ${infoColor[0]};
      color: ${whiteColor};
      font-size: 0.6rem;
      line-height: 1rem;
      &.mine {
        background: ${warningColor[0]};
      }
      &.unlike {
        background: ${dangerColor[0]};
      }
    }
    & svg, & span {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }
`
export const ImageStyled = styled(LazyLoadImage)`
`