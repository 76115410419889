import { gql } from '@apollo/client';


export const DASHBOARD_HOME_COLLECTIONS_QUERY = gql`
  query DashboardHomeCollectionsQuery(
    $page: PageFilter!,
    $filter: UserCollectionFilter!) {
      userCollectionExPage(
        page: $page,
        filter: $filter) {
          page
          total
          data {
            userCollection {
              collection {
                code
                name
              }
              priority
              lastUpdated
              settings
            }
            countFaults
            countRepeated
          }
        }
    }
`
export const DASHBOARD_HOME_CHANGES_COMPLETED_QUERY = gql`
  query DashboardHomeChangesCompletedQuery(
    $page: PageFilter!,
    $filter: UserChangeFilter!) {
      userChangesPage(
        page: $page,
        filter: $filter) {
          page
          total
          data {
            id
          }
        }
    }
`
export const DASHBOARD_HOME_CHANGES_QUERY = gql`
  query DashboardHomeChangesQuery(
    $page: PageFilter!,
    $filter: UserChangeFilter!) {
      userExchangesPage(
        page: $page,
        filter: $filter) {
          page
          total
          data {
            change {
              id
              chat
              stateId
              startDate
              endDate
              lastUpdater
              him
            }
            user {
              iduser
              username
              avatar
              likes
              numChangesNok
              mine
            }
            exchanges {
              collection {
                code
                name
              }
              mine
              his
            }
          }
      }
    }
  `

export const DASHBOARD_ALERTS_QUERY  = gql`
  query DashboardAlertsQuery(
    $filter: AlertFilter!) {
      allAlerts(
        filter: $filter) {
        title
        urlContent
        urlTrigger
      }
    }
`



export const DASHBOARD_COLLECTIONS_QUERY = gql`
  query DashboardCollectionsQuery(
    $filter: UserCollectionFilter!) {
      userCollectionEx(
        filter: $filter) {
          userCollection {
            collection {
              code
              name
              year
              key
              subject
            }
            priority
            lastUpdated
            settings
            countElements
          }
          countFaults
          countRepeated
          exchanges {
            change {
              id
              stateId
            }
          }
        }
  }
`

export const DASHBOARD_CHANGES_QUERY = gql`
  query DashboardChangesQuery(
    $filter: UserChangeFilter!) {
      allUserExchanges(
        filter: $filter)
        {
          change {
            id
            chat
            stateId
            startDate
            endDate
            lastUpdater
            him
          }
          user {
            iduser
            username
            avatar
            lastLogin
            likes
            numChangesNok
            mine
          }
          exchanges {
            collection {
                code
                name
            }
            mine
            his
          }
      }
    }
`

export const DASHBOARD_CHANGES_ALL_COLLECTIONS_QUERY = gql`
query DashboardChangesAllCollectionsQuery (
  $filter: UserCollectionFilter!) {
    allUserCollections(
    filter: $filter) {
      collection {
        code
        name
      }
    }
  }
`

export const DASHBOARD_COLLECTIONS_NEW_USER_COLLECTIONS_QUERY = gql`
  query DashboardCollectionsNewUserCollectionsQuery (
    $filter: UserCollectionFilter!) {
    allUserCollections(
    filter: $filter) {
      collection {
        code
      }
    }
  }
`

export const DASHBOARD_COLLECTIONS_NEW_ALL_SEARCH_QUERY = gql`
  query DashboardUserCollectionsNewQuery {
    allSearchCollections
      {
        collection {
          code
          name
          year
          key
          subject
          description
        }
        collectors {
          total
          recent
        }
      }
  }
`

export const DASHBOARD_COLLECTIONS_NEW_SEARCH_PAGE_QUERY = gql`
  query DashboardCollectionsNewPageQuery(
    $page: PageFilter!,
    $filter: CollectionFilter!) {
      searchCollectionsPage(
        page: $page,
        filter: $filter) {
          page
          total
          data {
            collection {
              code
              name
              year
              key
              subject
            }
            collectors {
              total
              recent
            }
          }
        }
    } 
`

export const DASHBOARD_CHANGES_SEARCH_QUERY = gql`
  query DashboardChangesSearchQuery(
    $filter: UserSearchChangeFilter!) {
      allNewExchanges(
        filter: $filter) {
          user {
            iduser
            username
            avatar
            lastLogin
            likes
            numChangesNok
            mine
          }
          exchanges {
            collection {
              code
              name
            }
            mine
            his
          }
        }
    }
`

export const POPUP_CHANGE_ELEMENTS_QUERY = gql`
  query ElementsQuery($filter: UserCollectionFilter!) {
      userCollectionEx(
        filter: $filter) {
          elements {
            code
            order
            number
            name
            nameEx
            group
            type
            comment
            comment2
          }
        }
    }
`

export const POPUP_CHANGE_UPDATE_CHAT_QUERY = gql`
  query PopupChangeUpdateChatQuery(
    $page: PageFilter!,
    $filter: UserChangeFilter!
  ) {
    userChangesPage(
      page: $page,
      filter: $filter
    ) {
      page
      total
      data {
        id
        chat
      }
    }
  }
`

export const USER_ADDRESS = gql`
query UserAddressQuery($filter: UserInfoFilter!) {
  userInfo(
    filter: $filter
  ) {
      address
      city
      place
      country
      postalCode
      name
      surname
    }
  }
`


export const POPUP_USER_QUERY = gql`
query PopupUserQuery($filter: UserInfoFilter!) {
  userInfoEx(
    filter: $filter
  ) {
      iduser
      numCollections
      numChangesStarted
      numChangesFinished
      numChangesWith
      numChangesNok
      lastChange
      lastChangeFinished
      lastLogin
      likes
      mine
    }
  }
`

export const POPUP_COLLECTION_QUERY = gql`
query PopupCollectionQuery($filter: UserCollectionFilter!) {
  userCollectionEx(
    filter: $filter
  ) {
      userCollection {
        collection {
          code
          name
          description
          year
          key
          subject
        }
        priority
        lastUpdated
        settings
      }
      exchanges {
        change {
          id
          stateId
          startDate
        }
      }
      countFaults
      countRepeated
  }
}
`

export const POPUP_COLLECTION_EDIT_QUERY = gql`
query PopupCollectionEditQuery($filter: UserCollectionFilter!) {
  userCollectionEx(
    filter: $filter
  ) {
      userElements {
        code
        order
        state
        type
        index
      }
      elements {
        code
        order
        number
        name
        nameEx
        group
        type
        comment
        comment2
      }
  }
}
`

export const POPUP_COLLECTION_SHARE_QUERY = gql`
query PopupCollectionShareQuery($filter: UserCollectionFilter!) {
  userCollectionEx(
    filter: $filter
  ) {
      userElements {
        code
        order
        state
        type
        index
      }
      elements {
        code
        order
        number
        name
        nameEx
        group
        type
        comment
        comment2
      }
  }
}
`

export const INDEX_TOP_COLLECTIONS_QUERY = gql`
query IndexTopCollectionsQuery(
  $page: PageFilter!,
  $filter: CollectionFilter!) {
    searchCollectionsPage(
      page: $page,
      filter: $filter)
    {
      page
      total
      data {
        collection {
          code
          name
        }
        collectors {
          total
          recent
        }
      }
    }
}
`

export const INDEX_LAST_COLLECTIONS_QUERY = gql`
query IndexLastCollectionsQuery(
  $page: PageFilter!,
  $filter: CollectionFilter!) {
  collectionsPage(
    page: $page,
    filter: $filter
  ) {
      page
      total
      data {
        code
        name
      }
    }
}
`

export const INDEX_CHANGES_QUERY = gql`
query IndexChangesQuery(
  $page: PageFilter!,
  $filter: UserChangeFilter!) {
  userExchangesPage(
    page: $page,
    filter: $filter
  ) {
      total
        data {
          change {
            id
            stateId
            startDate
            endDate
            lastUpdater
            him
          }
          user {
            username
            avatar
          }
          exchanges {
            collection {
                code
                name
            }
            mine
            his
          }
        }
      }
    }
`