import { Grid } from 'components/core'
import { NavLeft, NavRight } from 'components/header/styled'
import { Layout } from 'components/layout/Layout'
import { ArticleStyled } from 'components/layout/styled'
import { Icon } from 'components/styled'
import { HeaderMenu } from 'compose/header/HeaderMenu'
import { HeaderUser } from 'compose/header/HeaderUser'
import React, { FC, memo } from 'react'
import icons from 'components/theme/icons'
import { IAllStringProps, IChildren } from 'utils/interface'
import { MainGridStyled, LinkSideStyled, TitleStyled, TitleCenteredStyled } from './common.styled'
import { Logo } from 'components/logo/Logo'



interface ILogo {
  brand?: string;
}

export const HeaderLogo: FC<ILogo> = ({
}) => (
  <NavLeft>
    <Logo />
  </NavLeft>
)

export const HeaderMenuAndUser: FC = ({
}) => (
  <NavRight>
    <HeaderUser />
    <HeaderMenu />
  </NavRight>
)

interface ITitle {
  value?: string;
  children?: React.ReactNode;
}

export const Main: FC<IChildren> = memo(({
  children
}) => (
  <Layout>
    <Grid container spacing={4}>
      <MainGridStyled item sm={12}>{children}</MainGridStyled>
    </Grid> 
  </Layout>
))

export const ShowListButton: FC<IAllStringProps & IChildren> = ({
  linkTo,
  children
}) => (
  <LinkSideStyled to={linkTo}>
    <span>{children}</span>
    <Icon icon={icons.right} />
  </LinkSideStyled>
)


export const SubHeader: FC<IChildren> = memo(({
  children
}) => (
  <ArticleStyled>
    <Grid container spacing={4}>
      <MainGridStyled item sm={12}>{children}</MainGridStyled>
    </Grid>
  </ArticleStyled>
))

export const Title: FC<ITitle> = memo(({
  value,
  children = ''
}) => (
  <TitleStyled>
    { value && <span>{value}</span>}
    {children}
  </TitleStyled>
))

export const TitleCentered: FC<IChildren> = memo(({
  children
}) => (
  <TitleCenteredStyled>
    {children}
  </TitleCenteredStyled>
))



