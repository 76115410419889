import styled, { css } from 'styled-components'
import { Icon } from 'components/styled'
import { grayColor, infoColor } from 'components/theme/constants'
import { Autocomplete, Chip, IconButton, TextField } from 'components/core'
import shadows from 'components/theme/shadows'

export const AutocompleteStyled = styled(Autocomplete)`
  flex: 1;

  & > div {
    width: 100%;
  }
`
export const FilterChip = styled(Chip)`
  height: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
`
export const FilterIconStyled = styled(Icon)`
  color: ${grayColor[0]};
`
export const FilterFormStyled = styled.form.attrs((props) => {
  noValidate: true
})`
  margin: 0;
  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.5rem;
  }
  & .MuiTextField-root {
    width: 50%;
  }
  & .Mui-disabled {
    background-color: ${grayColor[6]};
  }
  & .MuiSelect-root, & .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    font-size: 0.85rem;
    line-height: 1rem;
  }
  & > button {
    &:hover {
      background-color: ${infoColor[0]};
    }
  }
`
export const DrawerFilterFormStyled = styled(FilterFormStyled)`
  display: grid;
  grid-row-gap: 0.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      grid-column-gap: 0.5rem;
      grid-template-columns: 1fr 1fr;
    }
    ${theme.breakpoints.up('md')} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  `}
`

export const IconButtonStyled = styled(IconButton)`
  font-size: 0.7rem;
  border: 1px solid gray;
  border-radius: 5px;
`

export const ListButtonStyled = styled(IconButtonStyled)`
  height: 3rem;
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  box-shadow: ${shadows.innerShadow};
  &:active {
    box-shadow: ${shadows.shadowFocus};
  }
`

export const TextFieldStyled = styled(TextField).attrs(props => ({
  margin: 'dense',
  variant: 'outlined'
}))`
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;

  & fieldset {
    border-color: grey;
  }
  & .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 1rem;
  }
`
