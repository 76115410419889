
import moment from 'moment'
import { firstBy } from 'thenby'
import objectPath from 'object-path'
import { ISortModel } from 'utils/interface'
import SortTypeEnum from 'use/filter/store/model/SortTypeEnum'


export default class SortUtility {

  static sortBy(arr: ISortModel[]) {
    function getSortFn(model: ISortModel) {
      const { name } = model
      const fn = model.type === SortTypeEnum.Custom
        ? model.fn
        : SortUtility.compare[model.type]
      return SortUtility.sortFn(model.asc, fn, name)
    }

    
    if (arr && arr.length) {
      const s0 = getSortFn(arr[0])
      const s1 = arr[1] && getSortFn(arr[1])

      if (s1) {
        return firstBy(s0).thenBy(s1)
      } else return firstBy(s0)
    }
  }

  static sortObject(list: any[], model: ISortModel, name: string) {
    const fn = SortUtility.compare[model.type]

    return list.sort(SortUtility.sortFn(model.asc, fn, name))
  }

  static sortByType(list: any[], type: SortTypeEnum, asc: boolean) {
    return list.sort((a, b) => SortUtility.compare[type](asc ? a : b, asc ? b : a))
  }

  static sortFn(asc: boolean, fnSort, colName: string) {
    const fnDesc = function (v1, v2) {
      //  console.log({ v1, v2, colName }, objectPath.get(v1,colName), objectPath.get(v2,colName));
      return fnSort(objectPath.get(v1,colName), objectPath.get(v2,colName));
    };
    const fnAsc = function (v1, v2) {
      // console.log({ v1, v2, colName }, objectPath.get(v1,colName), objectPath.get(v2,colName));
      return fnSort(objectPath.get(v2,colName), objectPath.get(v1,colName));
    };

    return asc ? fnAsc : fnDesc;
  };

  static compare = {
    number: function (v1: number, v2: number) {
      return v2 - v1;
    },
    string: function (v1: string, v2: string) {
      return v1 < v2 ? 1 : (v1 > v2 ? -1 : 0);
    },
    date: function (v1: string, v2: string) {
      var d1 = moment(v1);
      var d2 = moment(v2);
      var d70 = moment('1970');
      if (!d1.isValid()) { d1 = d70; }
      if (!d2.isValid()) { d2 = d70; }

      return d2.isAfter(d1)
        ? 1
        : (d2.isBefore(d1)
          ? -1 : 0)
    },
    digits: function (b, a) {
      var reg = /(\d+)/;
      var alist = a.split(reg); // split text on change from anything to digit and digit to anything
      var blist = b.split(reg); // split text on change from anything to digit and digit to anything

      if (alist.slice(-1) === '') { // remove the last element if empty
        alist.pop();
      }
      if (blist.slice(-1) === '') { // remove the last element if empty
        blist.pop();
      }

      for (var i = 0, len = alist.length; i < len; i++) {
        if (alist[i] !== blist[i]) { // find the first non-equal part
          if (alist[i].match(/\d/)) { // if numeric
            return +alist[i] - +blist[i]; // compare as number
          } else {
            return alist[i].localeCompare(blist[i]); // compare as string
          }
        }
      }
      return true;
    }
  }
};