import {
  DocumentNode,
  LazyQueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery as u
} from '@apollo/client'
import { useState } from 'react'
import { IQueryFields, IPageProps, IMutationInput } from 'use/fetch/interface'
import useI18n from 'use/i18n/useI18n'
import { useModalAlert } from 'use/modal/useModal'

const direction = 'ASC'
export const perPage = 10

export const useQuery = function <TData = any, TVariables = any>
(query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: any): QueryResult<TData, TVariables> {
  return u(query, { variables: options })
}
export const useQueryAll = function <T>
(id: string, query: DocumentNode, options?: LazyQueryHookOptions): IQueryFields<T> {
  const [get, { error, loading, data }] = useLazyQuery(query, {...{
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  }, ...options })

  const doFetch = function(filterProps: any) {   
    get({
      variables: {
        filter: filterProps
      }
    })
  }

  const resultData = (data || {})[id]
    return {
      ...{ error, loading: loading, get: doFetch },
      ...{ list: resultData }
    }
}
export const usePageQuery = function <T>
(
  id: string,
  query: DocumentNode,
  page: Partial<IPageProps> = {}
): IQueryFields<T> {
    const [ filterProps, setFilterProps ] = useState()

    const pageProps = {
      ...{
        per_page: page.per_page || perPage,
        direction: page.direction || direction
      },
      ...page.sort && { sort: page.sort },
      ...page.noMerge && { noMerge: true },
    }

    const [get, { loading, data, error }] = useLazyQuery(query, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    })

    const doFetch = function(filterProps: any, page: number) {  
      const variables = {
        variables: {
          page: {
            ...pageProps,
            ...{ page: page || 0 }
          },
          filter: filterProps
        }
      }
      get(variables)
      setFilterProps(filterProps)
    }

    const fetchMore = (dataLen: number) => {
      return () => {
        const pageValue = dataLen / pageProps.per_page
        doFetch(filterProps, pageValue)
      }
    }


    
    const resultData = (data || {})[id]

    // useEffect(() => {
    //   if (error) {
    //     open({
    //       errorMessage: 'server.error',
    //       error: JSON.stringify(resultData, null, 2),
    //       severity: 'error'
    //     })
    //   }
    // }, [error])
    
    return {
      ...{ error, loading, get: doFetch },
      ...resultData
        ? {
          list: resultData.data,
          total: resultData.total,
          fetchMore: resultData.total > (resultData.data || []).length
            ? fetchMore((resultData.data || []).length) : null
        }
        : {}
    }
  }


export const  useControlledMutation = ({
  name, mutation, success, fail, refetch, okMessage
}: IMutationInput) => {
  const { open: openAlert } = useModalAlert()
  const { t } = useI18n()

  return useMutation(mutation, {
    awaitRefetchQueries: true,
    onCompleted: e => {
      const result = e[name]
      if (result > 0) {
        if (success) success()
        if (okMessage) {
          openAlert({
            errorMessage: t(`server.ok.${name}`),
            severity: 'success'
          })
        }
      } else if (result < 0) {
        openAlert({
          errorMessage: t(`server.ko.${name}.${Math.abs(result)}`),
          severity: 'error'
        })
      } else {
        if (fail) fail()
        openAlert({
          errorMessage: t('server.database'),
          severity: 'error'
        })
      }
    },
    refetchQueries: refetch,
  })
}